import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {config} from '../Constant';
import PetInfoCardSkeleton from './PetInfoCardSkeleton';
import ContactInfoCardSkeleton from './ContactInfoCardSkeleton';
import {Button, Grid, Link, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PetExtraInfoCard from "./PetExtraInfoCard";
import ContactInfoCardMicrochip from "./ContactInfoCardMicrochip";
import PetInfoCardMicrochip from "./PetInfoCardMicrochip";
import PetPicturesCarousselMicrochip from "./PetPicturesCarousselMicrochip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";

const API_URL = config.url.API_URL;

export interface PetInfoFromResearchProps {
    petIdentification: string;
}

const PetInfoFromResearch: React.FunctionComponent<PetInfoFromResearchProps> = (props) => {
    const {t} = useTranslation();

    let [petName, setPetName] = useState<string>('');
    let [petSex, setPetSex] = useState<string>('');
    let [petDateOfBirth, setPetDateOfBirth] = useState<Date>(new Date());
    let [petSpecies, setPetSpecies] = useState<string>('');
    let [petBreed, setPetBreed] = useState<string>('');
    let [petWeightInPounds, setPetWeightInPounds] = useState<number>(0);
    let [petDescription, setPetDescription] = useState<string>('');
    let [petIsOutdoor, setPetIsOutdoor] = useState<boolean>(false);
    let [petIsLost, setPetIsLost] = useState<boolean>(false);
    let [petIsVerified, setPetIsVerified] = useState<boolean>(false);

    let [noPetWithThatMicrochip, setNoPetWithThatMicrochip] = useState<boolean>(false);

    let [petPicturesList, setPetPicturesList] = useState<string[]>([]);
    let [contactList, setContactList] = useState<any[]>([]);


    useEffect(() => {
        setNoPetWithThatMicrochip(false);
        fetchContactInfo();
        fetchPetInfo();
    }, [props.petIdentification]);

    const fetchContactInfo = () => {
        axios.get(`${API_URL}getContactInfoFromPetIdentification/${props.petIdentification}`)
            .then(function (response) {
                if (response.status === 200) {
                    setContactList(response.data.owners)
                } else {
                    setNoPetWithThatMicrochip(true)
                }
            })
            .catch(function (error) {
                setNoPetWithThatMicrochip(true)
            })
    };

    const fetchPetInfo = () => {
        axios.get(`${API_URL}getPetInfoFromPetIdentification/${props.petIdentification}`)
            .then(function (response) {
                if (response.status === 200) {
                    setPetName(response.data.pet.Name);
                    setPetSex(response.data.pet.Sex);
                    setPetSpecies(response.data.pet.Species);
                    setPetBreed(response.data.pet.Breed);
                    setPetWeightInPounds(response.data.pet.Weight * 2.205);
                    setPetDescription(response.data.pet.Notes);
                    setPetIsOutdoor(response.data.pet.isOutdoor);
                    setPetIsLost(response.data.pet.isLost);
                    setPetIsVerified(response.data.pet.tagCount > 0);

                    setPetPicturesList(response.data.petPicturesUrl);
                    setPetDateOfBirth(new Date(response.data.pet.DOB));
                } else {
                    setNoPetWithThatMicrochip(true);
                }
            })
            .catch(function (error) {
                setNoPetWithThatMicrochip(true);
            });
    };

    return (
        <div>
            {noPetWithThatMicrochip ? (
                <div>
                    <Typography padding={"1rem"}>{t('no_microchip_page_title')}</Typography>
                    <Typography padding={"1rem"}>
                        <Link
                            href={`https://www.aaha.org/for-veterinary-professionals/microchip-search/?microchip_id=${props.petIdentification}`}
                            target="_blank" rel="noopener noreferrer">
                            {t('microchip_Link_to_aaha')}
                        </Link>
                    </Typography>
                    <Typography padding={"1rem"}>
                        <Link href={"/download"} target="_blank" rel="noopener noreferrer">
                            {t('microchip_Link_to_download_app')}
                        </Link>
                    </Typography>
                </div>
            ) : (
                <Paper elevation={5} style={{padding: '1rem', margin: '1rem', borderRadius: '20px',}}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} md={6} lg={8}>
                            {petName ? (
                                <PetInfoCardMicrochip petName={petName}
                                                      petSpecies={petSpecies}
                                                      petBreed={petBreed}
                                                      petDescription={petDescription}
                                                      petIsOutdoor={petIsOutdoor}
                                                      petIsLost={petIsLost}
                                                      petMicrochipNumber={props.petIdentification}
                                                      petIsVerified={petIsVerified}
                                />
                            ) : (
                                <PetInfoCardSkeleton/>
                            )}
                            {
                                contactList && contactList.length > 0 ? (
                                    <ContactInfoCardMicrochip contactList={contactList}/>
                                ) : (
                                    <ContactInfoCardSkeleton/>
                                )
                            }
                            {
                                petName ? (
                                    <PetExtraInfoCard petDateOfBirth={petDateOfBirth}
                                                      petSex={petSex}
                                                      petWeightInPounds={petWeightInPounds}/>
                                ) : (
                                    <PetInfoCardSkeleton/>
                                )
                            }
                            {
                                <Grid container justifyContent="flex-end">
                                    <Grid item style={{paddingRight: '1rem'}}>
                                        <Button
                                            variant="contained"
                                            style={{
                                                padding: "1rem",
                                                borderRadius: 10,
                                                marginBottom: "1rem"
                                            }}
                                            onClick={() => (window.location.href = "https://app.vetygo.ca/")}
                                        >
                                            {t("consult_medical_history")}
                                            <FontAwesomeIcon icon={faLock} fontSize={"1.5rem"}
                                                             style={{marginLeft: "0.5rem"}}/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <PetPicturesCarousselMicrochip petPicturesList={petPicturesList}/>
                        </Grid>
                    </Grid>
                </Paper>)}
        </div>
    );
};

export default PetInfoFromResearch;
